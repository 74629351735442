import { getApi } from "../../@hooks/useApi";
import { NoteTypePagination } from "../../@types/NoteType";

export type Data = {
  currentPage: number;
  nameOrCpf: string;
};

export type GetProductResponse = {};

export const getNotes = async ({ currentPage, nameOrCpf }: Data) => {
  const LIMIT = 20;
  const { api } = await getApi({});

  const response = await api.get<NoteTypePagination>(
    `/omie/list-nota-entrada?page=${currentPage}&limit=${LIMIT}&pesquisa=${nameOrCpf}`
  );
  return response.data;
};
