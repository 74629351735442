import { Pagination } from "react-bootstrap";
import { ColorsConstants } from "../../themes/colorsConstants";

interface PaginationComponentProps {
  pages: number;
  page: number;
  setPage: (newPage: number) => void;
}

export function PaginationComponent({
  pages,
  page,
  setPage,
}: PaginationComponentProps) {
  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 3;
    const firstPage = 1;
    const lastPage = pages;

    // Define os limites para as páginas visíveis
    const startPage = Math.max(
      firstPage,
      Math.min(
        page - Math.floor(maxVisiblePages / 2),
        pages - maxVisiblePages + 1
      )
    );
    const endPage = Math.min(lastPage, startPage + maxVisiblePages - 1);

    // Primeira página e reticências iniciais
    if (startPage > firstPage) {
      items.push(
        <Pagination.Item key={firstPage} onClick={() => setPage(firstPage)}>
          {firstPage}
        </Pagination.Item>
      );
      if (startPage > firstPage + 1) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => setPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Última página e reticências finais
    if (endPage < lastPage) {
      if (endPage < lastPage - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={lastPage} onClick={() => setPage(lastPage)}>
          {lastPage}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => setPage(Math.max(page - 1, 1))}
        disabled={page === 1}
      />
      {renderPaginationItems()}
      <Pagination.Next
        onClick={() => setPage(Math.min(page + 1, pages))}
        disabled={page === pages}
      />
    </Pagination>
  );
}
